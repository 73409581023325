/* Global Styles */
:root {
  /* --primary-color: #17a2b8; */
  --primary-color: rgb(207, 83, 0);
  --primary-rgb: 207, 83, 0;
  --dark-color: rgb(52, 58, 64);
  --dark-rgb: 52, 58, 64;
  --light-color: rgb(244, 244, 244);
  --light-rgb: 244, 244, 244;
  --danger-color: var(--primary-color);
  --danger-rgb: var(--primary-rgb);
  --success-color: rgb(38, 167, 222);
  --success-rgb: 38, 167, 222;
  --box-background-color: rgb(32, 30, 30);
  --box-background-rgb: 32, 30, 30;
  /* --box-background-color: var(--dark-color); */
}

.float-container {
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  margin: 15px 0 0 0;
  min-height: fit-content;
}

.float-child-left {
  width: 35vw;
  max-width: 500px;
  float: left;
  margin-left: 15px;
  padding: 25px 10px;
  height: fit-content;
}

.float-child-right {
  width: 35vw;
  max-width: 500px;
  float: right;
  margin-right: 15px;
  padding: 25px 10px;
  height: fit-content;
}

.float-child-half-wide {
  width: 50%;
  min-width: 80px;
  align-self: center;
}

.float-child-wide {
  width: 100%;
}

.player-name {
  display: block;
  font-size: 1.5rem;
  transition-property: transform;
  transition-timing-function: ease;
  transition-duration: 4000ms;
}

.player-picture {
  display: block;
  width: 100%;
  margin: auto;
}

.player-picture-fade {
  animation: fade 4000ms ease 0ms 2 alternate forwards;
}

.player-name-rise {
  display: block;
  animation: rise 3000ms ease 0ms 2 alternate forwards;
}

.player-name-fall {
  display: block;
  animation: fall 3000ms ease 0ms 2 alternate forwards;
}

@keyframes rise {
  0% {
    transform: translateY(0);
    opacity: 100%;
    color: white;
  }

  20% {
    color: limegreen;
  }
  100% {
    transform: translateY(-35px);
    opacity: 20%;
    color: limegreen;
  }
}

@keyframes fall {
  0% {
    transform: translateY(0);
    opacity: 100%;
    color: white;
  }

  20% {
    color: red;
  }
  100% {
    transform: translateY(35px);
    opacity: 20%;
    color: red;
  }
}

@keyframes fade {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

.flex-buttons {
  display: flex;
  justify-content: left;
  padding: 30px;
}

.flex-buttons-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tweet-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border: 20px solid red;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Raleway", sans-serif;
  font-size: 1.3rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wraps;
  height: 150px;
}

/* Utilities */
.container {
  position: absolute;
  left: 50%;
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 19rem;
  margin-bottom: 3rem;
}

/*
.container-centered {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1100px;
  margin: auto;
  margin-top: 50px; 
  overflow: hidden;
  padding: 3rem;
  margin-bottom: 3rem;
  background: rgba(255, 255, 255, 0.638);
  border: solid rgb(0, 0, 0);
  border-width: 1px;
}
*/

.container-dashboard {
  min-height: calc(100vh);
  background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
    url("./img/scratchedcourt.jpg") no-repeat center center/cover;
  background-attachment: fixed;
  margin-top: 2vh;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-centered {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1600px;
  padding: 4rem 8rem;
  padding-bottom: 4rem;
  color: white;
  line-height: 1.3;
}

.container-rater,
.container-dashboard h1 {
  color: white;
}

.container-tweet {
  float: right;
  overflow: hidden;
  padding: 1rem;
  margin: 2rem;
}

/*
.container-dashboard {
  position: relative;
  background: url('./img/bballbackground.jpg') no-repeat center center/cover;
  height: 50vh;
  margin-top: 50px
}
*/

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.flex-row {
  display: flex;
  justify-content: space-around;
}

.button-v {
  margin: 3% 0 10% 0;
  cursor: pointer;
}

.button-div,
.button-div-loser {
  transition: 100ms;
}

.button-div:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.15);
  transition-delay: 20ms;
}

.button-div-winner {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.15);
}

.landing {
  position: relative;
  background: url("./img/darkshot.jpg") no-repeat center center/cover;
  height: 100vh;
  margin-top: 50px;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 0;
  margin: auto;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  max-width: 55ch;
}

.directions {
  font-size: 1.05rem;
  padding: 1rem 0 0 0;
  text-align: center;
  margin: auto;
  width: 40ch;
}

.leaderboard-div {
  height: 75vh;
  overflow-y: scroll;
  width: 60vw;
  max-width: 1100px;
}

.leaderboard-table {
  margin-top: 2vh;
  border-collapse: collapse;
  width: 100%;
  position: relative;
  margin: auto;
  height: 60%;
  overflow: scroll;
}

.leaderboard-table tr th {
  background-color: var(--primary-color);
  position: sticky;
  top: 0;
  z-index: 1;
  /* border: solid 1px; */
}

.leaderboard-table th:nth-child(2) {
  width: 34%;
}

.leaderboard-table th:nth-child(1),
.leaderboard-table th:nth-child(3),
.leaderboard-table th:nth-child(4),
.leaderboard-table th:nth-child(5),
.leaderboard-table th:nth-child(6),
.leaderboard-table th:nth-child(7) {
  width: 12%;
}

.leaderboard-table td {
  /* border: solid 1px; */
  text-align: center;
  padding: 5px;
}

.leaderboard-table tr:nth-child(odd) {
  text-align: center;
  background-color: rgba(var(--dark-rgb), 0.4);
}

.leaderboard-table tr:nth-child(even) {
  background-color: rgba(var(--light-rgb), 0.4);
}

.text-center {
  text-align: center;
  position: relative;
  margin: auto;
}

.text-primary {
  color: var(--primary-color);
}

.text-light {
  color: var(--light-color);
}
.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-skip {
  margin: auto;
  padding: 8px 100px;
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.form input[type="submit"],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

/* Navbar */
.navbar-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0rem 2rem;
  position: fixed;
  height: 60px;
  width: 100%;
  flex-wrap: nowrap;
  top: 0;
  border-bottom: solid 4px var(--primary-color);
  z-index: 5;
}

.navbar-ul {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: 100%;
  justify-content: flex-end;
  color: #fff;
  padding: 0rem;
  margin: 0 0rem;
}

.navbar-ul li {
  font-size: medium;
}

.navbar-link {
  color: #fff;
  word-spacing: 10;
  transition: transform 300ms;
}

.navbar-link h1 {
  white-space: nowrap;
}

.navbar-link:hover {
  color: var(--primary-color);
  h1 {
    transition: all 300ms;
    transform: scale(1.1);
  }
}

.navbar-ul li:hover,
.navbar-ul a:hover {
  transition: all 150ms;
  transform: scale(1.1);
  color: var(--primary-color);
}

.navbar-ul a {
  color: #fff;
  padding: 0rem;
  margin: 2 0rem;
}

.navbar-ul a:hover {
  color: var(--primary-color);
}

span {
  font-size: medium;
  margin-right: 1rem;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Mobile Styles */
@media only screen and (max-width: 800px) {
  .landing {
    position: relative;
    background: url("./img/darkshot.jpg") no-repeat center center/cover;
    margin-top: 100px;
    padding-top: 80px;
    min-width: 100vw;
    height: calc(100vh - 100px);
  }

  .landing-inner {
    margin-top: 50px;
    padding-top: 50px;
    height: fit-content;
  }

  .container-dashboard {
    min-height: calc(100vh - 90px);
    margin: 110px 0px 0px;
    padding: 5px 0px 0px 0px;
    display: block;
    justify-content: baseline;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .container-centered {
    position: relative;
    max-width: 100vw;
    min-width: 95vw;
    min-height: calc(100vh - 270px);
    padding: 3vh 5px 8vh 5px;
    margin-top: 10px;
  }

  .float-child-left,
  .float-child-right {
    height: 70%;
    max-width: 80%;
    width: fit-content;
    padding: 15px 20px;

    height: auto;
    /* max-height: 35vh; */
    margin: 5px 0px;
    border: solid #333 8px;
    border-radius: 8%;
    line-height: 1.15;
  }

  .button-div:hover {
    transition: none;
    transform: none;
  }

  .player-name {
    margin: 0 auto;
  }

  .player-name h4 {
    margin: 0 auto;
    padding: 4px;
    line-height: 1;
  }

  .player-picture {
    margin: 0px auto;
    display: block;
    padding: 0;
    max-width: 80%;
    object-fit: contain;
    height: 100px;
  }

  .button-div {
    height: 70%;
    max-width: 80%;
    width: fit-content;
    padding: 15px 40px;
  }

  .player-name-fall,
  .player-name-rise {
    border-width: 0;
    transition: 0.4s;
  }

  .container-tweet {
    display: none;
    visibility: hidden;
  }

  .float-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-top: 5px;
    align-items: center;
    justify-content: space-between;
    gap: 0;
    max-width: 100%;
    margin: auto;
  }

  .container {
    margin-top: 8rem;
  }

  .leaderboard-div {
    max-height: calc(100vh - 200px);
    height: 85vh;
    min-height: 200px;
    width: 90vw;
    overflow-y: scroll;
    margin: 10px auto auto auto;
  }
  .leaderboard-table {
    width: 90%;
    margin: auto;
    position: relative;
  }

  .leaderboard-table td {
    /* border: solid 1px; */
    text-align: center;
    padding: 2px;
  }

  .leaderboard-table th:nth-child(4),
  .leaderboard-table th:nth-child(5),
  .leaderboard-table th:nth-child(6),
  .leaderboard-table th:nth-child(7),
  .leaderboard-table td:nth-child(4),
  .leaderboard-table td:nth-child(5),
  .leaderboard-table td:nth-child(6),
  .leaderboard-table td:nth-child(7) {
    display: none;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 2.5rem;
  }

  .large {
    font-size: 1.9rem;
  }

  .lead {
    font-size: 1rem;
  }

  .directions {
    font-size: 1rem;
    font-style: oblique;
    text-align: center;
    width: 20px;
    display: none;
  }

  /* Navbar */
  .navbar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 140px;
    line-height: 1.1;
  }

  .navbar-ul {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: left;
    justify-content: space-around;
    line-height: 1.5;
  }

  .navbar h1 {
    margin: 0rem;
    padding: 0;
    font-size: medium;
  }

  .navbar-container span {
    font-size: medium;
  }

  .navbar-link,
  .navbar-link h1 {
    font-size: 30px;
    margin-bottom: 0px;
    padding-bottom: 0%;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */

  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }

  .profile-grid {
    grid-template-areas:
      "top"
      "about"
      "exp"
      "edu"
      "github";
  }

  .profile-about .skills {
    flex-direction: column;
  }

  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .post {
    grid-template-columns: 1fr;
  }

  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }
}

.alert-wrapper {
  position: fixed;
  top: 4rem;
  right: 2rem;
  display: inline-block;
}
